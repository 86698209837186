import "./mapStyling.css"

import GoogleMapReact from "google-map-react"
import React, { useEffect, useRef, useState } from "react"
import { useRecoilState } from "recoil"
import styled from "styled-components"

// import Marker from './Marker';
import LogoP from "~images/pradera_logo_P.png"

import mapAtom from "~atoms/mapAtom"
import isOpenAtom from "./isOpenAtom"
import locationAtom from "./locationAtom"
const mapStyles = require("./mapStyles.json")

const LocationDiv = styled.div`
  transition: 0.5s;
  &.active {
    transform-origin: top center;
    transform: scale(1.5);
    svg path {
      fill: #003d6a;
    }
  }
  @at-root {
    z-index: ${props => (props.onTop ? 10 : 9)};
  }
`
const CustomSVG = styled.svg`
  transform: translate(-50%, -100%);
`

const MainWrapper = styled.div`
  position: relative;
  height: 959px;
  width: 100%;
  @media ${({ theme }) => theme.breakpointDown.md} {
    height: calc(100vh - 100px);
  }
`
const NewPin = styled.img`
  transform: translate(-50%, -100%);
  width: 26px;
  &:hover {
    cursor: pointer;
  }
`
const PortfolioMap = ({ locations }) => {
  const [position, setPosition] = useRecoilState(mapAtom)
  const [isOpen, setOpen] = useRecoilState(isOpenAtom)
  const [isActive, setActive] = useState(false)

  const [currentLocation, setCurrentLocation] = useRecoilState(locationAtom)
  const mapRef = useRef()
  const locationRef = useRef()

  useEffect(() => {
    console.log(mapRef.current)
    console.log(position)
    if (mapRef.current?.panTo && position) mapRef?.current?.panTo(position)
  }, [position])

  const OpenLocation = location => {
    setPosition({ lat: location.latitude, lng: location.longitude })
    // console.log(currentLocation)
    if (!isOpen) setOpen(!isOpen)
  }

  const toggleClass = () => {
    setActive(!isActive)
  }
  return (
    // Important! Always set the container height explicitly
    <MainWrapper>
      <GoogleMapReact
        options={{
          styles: mapStyles,
          maxZoom: 9,
          minZoom: 3,
          disableDefaultUI: false,
          zoom: 5,
        }}
        bootstrapURLKeys={{ key: "AIzaSyDsqAEj_1E7atrXAY2jTiHBEED1dqm_aPI" }}
        defaultCenter={{ lat: 51.41503534804848, lng: 10.212059071083331 }}
        defaultZoom={5}
        center={position || { lat: 51.41503534804848, lng: 10.212059071083331 }}
        ref={mapRef}
      >
        {locations &&
          locations.map(location => (
            <LocationDiv
              lat={location.latitude}
              lng={location.longitude}
              ref={locationRef}
              onTop={location.type == "Pradera Offices" ? true : false}
              className={
                location == currentLocation && isOpen ? "active" : null
              }
              // anchor={new google.maps.Point(0, 50)}
              onClick={() => {
                if (!location.disable) {
                  // console.log(location)
                  setCurrentLocation(location)
                  OpenLocation(location)
                }
                toggleClass()
              }}
            >
              {location.type !== "Pradera Offices" && (
                <CustomSVG
                  style={{ zIndex: -1 }}
                  width="14px"
                  height="26px"
                  viewBox="0 0 30 56"
                  cursor="pointer"
                >
                  <g>
                    <g>
                      <path
                        d="M15,0C6.72,0,0,6.06,0,16c0,3.58,2.11,9,3.34,12L15,56,26.66,28C27.89,25,30,19.58,30,16,30,6.06,23.28,0,15,0Zm0,8a7,7,0,1,1-7,7A7,7,0,0,1,15,8Z"
                        fill={
                          !location.live
                            ? "#8a8170"
                            : location.type === "Shopping Centre"
                            ? "#92D1F4"
                            : location.type === "Retail Park"
                            ? "#6FC2B4"
                            : location.type === "Pradera Offices"
                            ? "#EE751A"
                            : "grey"
                        }
                      />
                    </g>
                  </g>
                </CustomSVG>
              )}
              {location.type == "Pradera Offices" && <NewPin src={LogoP} />}
            </LocationDiv>
          ))}
      </GoogleMapReact>
    </MainWrapper>
  )
}

export default PortfolioMap
